<template>
    <div>
      <section id="news-area" :style="'background-position:50% 50%;'">
        <div class="contents">
            <div class="container">
                <div class="contents-main">
                    <h2 class="wow fadeInDown media-txt animated" data-wow-duration="1000ms" data-wow-delay="0.3s" style="visibility: visible;-webkit-animation-duration: 1000ms; -moz-animation-duration: 1000ms; animation-duration: 1000ms;-webkit-animation-delay: 0.3s; -moz-animation-delay: 0.3s; animation-delay: 0.3s;">新闻动态</h2>
                    <h2 class="wow fadeInUp bot-txt animated" data-wow-duration="1000ms" data-wow-delay="400ms" style="visibility: visible;-webkit-animation-duration: 1000ms; -moz-animation-duration: 1000ms; animation-duration: 1000ms;-webkit-animation-delay: 400ms; -moz-animation-delay: 400ms; animation-delay: 400ms;">News And Trends</h2>
                </div>
            </div>
        </div>
      </section>
      <section id="news-main" class="news-sec">
        <div class="container">
            <div class="row">
                <div id="idData" class="news-pagination" style="height: 1485px;">
                    <div class="n-pagi-main newsNavF" v-for="(item,index) in list" :key="index" :class="{displayNone:Math.ceil((index+ 1)/6) != num}">
                        <a target="_blank" :href="item.href">
                            <div class="n-p-left">
                                <el-image
                                style="width: 228px; height: 165px"
                                fit="cover"
                                :src="require(`../../assets/img/newsimg/${item.img}`)"
                                ></el-image>
                                <!-- <img :src='require(`${item.img}`)' alt="news image"> -->
                            </div>
                            <div class="n-p-right">
                                <h2>{{ item.t1 }}</h2>
                                <p>{{ item.t2 }}</p>
                                <h3>{{ item.t3 }}</h3>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="pagination-more">
                    <div class="p-m-inner">
                        <div class="m-i-head">
                            <h2>热门资讯</h2>
                        </div>
                        <div class="m-i-content">
                            <a href="https://mp.weixin.qq.com/s/lmlkykxJuVePEE8CXoBnUg" target="_blank">
                                <p>旺翔传媒参加普陀团区委、普陀区青企协会议</p>
                                <h3>发布日期：2022年07月10日</h3>
                            </a>
                        </div>
                        <div class="m-i-content">
                            <a href="https://mp.weixin.qq.com/s/vz-P9MGtZhJSuSkzbVP1Yg" target="_blank">
                                <p>荣誉 | 旺翔传媒子公司——科敦信息技术荣获“2021上海软件和信息技术服务业高成长百家”称号</p>
                                <h3>发布日期：2022年02月14日</h3>
                            </a>
                        </div>
                        <div class="m-i-content">
                            <a href="https://mp.weixin.qq.com/s/gdhsMjx-kWD3EcvgXISrsQ" target="_blank">
                                <p>【喜讯】热烈祝贺旺翔传媒荣获国家发明专利授权</p>
                                <h3>发布日期：2021年07月23日</h3>
                            </a>
                        </div>
                    </div>
                </div>
                <el-pagination
                    style="margin-top: 50px;text-align: center;"
                    background
                    @current-change="currentChange"
                    layout="prev, pager, next"
                    :pager-count="5"
                    :page-size="6"
                    :total="list.length">
                </el-pagination>
            </div>
        </div>
    </section>
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        list:[
            {
                href:'https://mp.weixin.qq.com/s/zhIKLPVCWY_Eo5TqEb1JVw',
                img:"29.jpg",
                t1:"旺翔数科欢庆建国74周年",
                t2:"上海旺翔数字科技股份有限公司祝愿祖国繁荣富强、人民幸福安康、公司事业蒸蒸日上！国庆节快乐！",
                t3:"发布日期：2023年09月30日",
            },
            {
                href:'https://mp.weixin.qq.com/s/1zFXPrSpT7o0e9062PavRg',
                img:"28.jpg",
                t1:"旺翔数科祝大家中秋节快乐！",
                t2:"值此中秋佳节到来之际，上海旺翔数字科技股份有限公司恭祝您身体健康、家庭和睦、事业有成、万事如意！中秋节快乐！",
                t3:"发布日期：2023年09月29日",
            },
            {
                href:'https://mp.weixin.qq.com/s/549VE1YAXLsjtDsk-RCQgA',
                img:"27.jpg",
                t1:"旺翔数科庆祝党的生日！",
                t2:"向时代致敬，不忘初心，砥砺前行。旺翔数科践行使命敬党，努力再创辉煌！",
                t3:"发布日期：2023年07月01日",
            },
            {
                href:'https://mp.weixin.qq.com/s/U51OI5yHEbmv8ZME1LT03w',
                img:"26.jpg",
                t1:"端午安康！",
                t2:"说一声吉祥，道一声平安！旺翔数科祝大家端午安康！",
                t3:"发布日期：2023年06月22日",
            },
            {
                href:'https://mp.weixin.qq.com/s/c15RAnUAiuoEKxUAESQMmw',
                img:"25.jpg",
                t1:"上海旺翔数字科技股份有限公司祝大家劳动节快乐！",
                t2:"向劳动者致敬，为梦想而奋斗，祝五一劳动节快乐！",
                t3:"发布日期：2023年04月29日",
            },
            {
                href:'https://mp.weixin.qq.com/s/ny8v11994JBo4F9PCe9I6Q',
                img:"24.jpg",
                t1:"国庆节快乐！",
                t2:"奋斗让我们充实，拼搏让我们精彩，祝福让我们温暖！国庆佳节到来之际，旺翔数科祝您：身体健健康康、事业蒸蒸日上、诸事皆遂心愿！",
                t3:"发布日期：2022年10月01日",
            },
            {
                href:'https://mp.weixin.qq.com/s/8FG9G74OUQLhTC2uG8yRtA',
                img:"23.jpg",
                t1:"上海市总商会和上海市工商业联合会发来抗疫工作感谢信",
                t2:"近日，上海旺翔文化传媒股份有限公司收到了上海市总商会和上海市工商业联合会给公司发来的抗疫工作感谢",
                t3:"发布日期：2022年07月15日",
            },
            {
                href:'https://mp.weixin.qq.com/s/lmlkykxJuVePEE8CXoBnUg',
                img:"22.jpg",
                t1:"旺翔传媒参加普陀团区委、普陀区青企协会议",
                t2:"近日，上海旺翔文化传媒股份有限公司应普陀团区委邀请，参加了普陀区青年企业家协会零售业交流分享会。",
                t3:"发布日期：2022年07月10日",
            },
            {
                href:'https://mp.weixin.qq.com/s/kv75zONU9OxyWL6Q22cp0w',
                img:"21.jpg",
                t1:"庆祝党的101岁生日！",
                t2:"建党一百零一年，沧海桑田，神州旧貌换新颜！祈福祖国永远繁荣昌盛，人民生活永远和谐美满，祝党生日快乐！",
                t3:"发布日期：2022年07月01日",
            },
            {
                href:'https://mp.weixin.qq.com/s/kAtb1jPdbq4CXA3hSxK_4Q',
                img:"20.jpg",
                t1:"父亲节快乐！",
                t2:"父亲是山，比山更伟岸；父亲是海，比海更包容。祝所有父亲节日快乐！",
                t3:"发布日期：2022年06月19日",
            },
            {
                href:'https://mp.weixin.qq.com/s/pASZzsi2q58xbMDz9qTC4Q',
                img:"19.jpg",
                t1:"祝大家端午节安康！",
                t2:"端午节，祝福悄然送到。愿你事业像龙舟一样快进，家庭像香包一样温馨，个性像棕叶一样飘逸，快乐健康像糯米一样粘上",
                t3:"发布日期：2022年06月03日",
            },
            {
                href:'https://mp.weixin.qq.com/s/ycdw7L6gQ1GnjExSKx3MZA',
                img:"18.jpg",
                t1:"感恩！母亲节快乐！",
                t2:"母亲节，最美的祝福送给妈妈！愿妈妈快乐安康、幸福吉祥！",
                t3:"发布日期：2022年05月08日",
            },
            {
                href:'https://mp.weixin.qq.com/s/MzxFfp8DtJwA9Pl3cAsBsg',
                img:"17.jpg",
                t1:"同心抗疫，旺翔传媒在行动",
                t2:"同心抗疫，旺翔传媒在行动",
                t3:"发布日期：2022年05月03日",
            },
            {
                href:'https://mp.weixin.qq.com/s/-j_qUmezmeGJB5LZmVhVOg',
                img:"16.jpg",
                t1:"付出辛劳，挥洒汗水，收获成果！劳动节快乐！",
                t2:"在五一劳动节到来之际，衷心地问候一声：你辛苦了！愿你尽享劳动的幸福和丰收的喜悦。旺翔传媒祝朋友",
                t3:"发布日期：2022年05月01日",
            },
            {
                href:'https://mp.weixin.qq.com/s/TLJ6YOvaNCtiOOHeGaxiHA',
                img:"15.jpg",
                t1:"叮咚！旺翔传媒居家“心灵大礼包”来啦，请查收！",
                t2:"记录一下旺翔传媒给我们带来的温暖~",
                t3:"发布日期：2022年04月22日",
            },
            {
                href:'https://mp.weixin.qq.com/s/vz-P9MGtZhJSuSkzbVP1Yg',
                img:"14.jpg",
                t1:"荣誉 | 旺翔传媒子公司——科敦信息技术荣获“2021上海软件和信息技术服务业高成长百家”称号",
                t2:"近日，旺翔传媒子公司——科敦信息技术荣获“2021上海软件和信息技术服务业高成长百家”称号及“上海张江国家自主创新示范区专项发展资金重点项目高增长”专项资金。",
                t3:"发布日期：2022年02月14日",
            },
            {
                href:'https://mp.weixin.qq.com/s/JDaqpkxCWNy74rpN0Fm7wg',
                img:"13.jpg",
                t1:"虎力全开，跃进新征程！",
                t2:"",
                t3:"发布日期：2022年02月08日",
            },
            {
                href:'https://mp.weixin.qq.com/s/gdhsMjx-kWD3EcvgXISrsQ',
                img:"12.jpg",
                t1:"【喜讯】热烈祝贺旺翔传媒荣获国家发明专利授权",
                t2:"热烈祝贺旺翔传媒获得国家发明专利授权 | 董事长 当选普陀区工商联第十五届执行委员",
                t3:"发布日期：2021年07月23日",
            },
            {
                href:'https://mp.weixin.qq.com/s/avW0vnpZEO5xYvyU1CM2nw',
                img:"12.jpg",
                t1:"【喜讯】热烈祝贺旺翔传媒荣获“中小企业服务机构”|“一带一路”首席营销官称号",
                t2:"热烈祝贺我司荣获2021年度“中小企业服务机构”称号与第五期“一带一路”首席营销官",
                t3:"发布日期：2021年07月09日",
            },
            {
                href:'https://mp.weixin.qq.com/s/1MpC7nmrRJMlXzQURQvDsg',
                img:"11.jpg",
                t1:"旺翔传媒热烈祝贺中国建党一百周年",
                t2:"一百年，风雨征程——旺翔传媒热烈祝贺中国共产党迎来百年华诞！",
                t3:"发布日期：2021年07月01日",
            },
            {
                href:'https://mp.weixin.qq.com/s/HIw-hiaB3oI_WZJSq05sRQ',
                img:"10.jpg",
                t1:"祝贺！神舟十二号载人飞船发射圆满成功！",
                t2:"据中国载人航天工程办公室消息，北京时间2021年6月17日9时22分，搭载神舟十二号载人飞船的长征二号F遥十二运载火箭，在酒泉卫星发射中心准时点火发射，顺利将聂海胜、刘伯明、汤洪波3名航天员送入太空，飞行乘组状态良好，发射取得圆满成功。",
                t3:"发布日期：2021年06月17日",
            },
            {
                href:'https://mp.weixin.qq.com/s/cKTn73-h9VynQGRV1Qee8g',
                img:"9.jpg",
                t1:"荣誉 | 旺翔传媒荣获金鼠标两项大奖",
                t2:"恭贺！旺翔传媒斩获金鼠标两项大奖。",
                t3:"发布日期：2021年04月30日",
            },
            {
                href:'https://mp.weixin.qq.com/s/47et63MMbGmJdhJ1cly-nA',
                img:"8.jpg",
                t1:"荣誉|旺翔传媒荣获金鼠标两项大奖",
                t2:"旺翔传媒凭借卓越的数字营销经验和创新营销思路，在众多优秀企业中脱颖而出，荣获金鼠标两项大奖。",
                t3:"发布日期：2020年08月04日",
            },
            {
                href:'https://mp.weixin.qq.com/s/O7gRYWYjiDvUIFvNtuZSpg',
                img:"7.jpg",
                t1:"国内首家 | 旺翔传媒助力mcn行业沪上开花",
                t2:"国内首家mcn专业委员会成立",
                t3:"发布日期：2020年05月19日",
            },
            {
                href:'https://mp.weixin.qq.com/s/ktSTJ9VmWSVabsyn8PKJRg',
                img:"6.jpg",
                t1:"旺翔传媒隆重举行2020“梦想起航”年会活动",
                t2:"金猪辞旧岁，瑞鼠迎新春。1月10日，旺翔传媒隆重举行2020“梦想起航”年会活动，旺翔传媒全体员工汇聚一堂，共享年度盛宴。",
                t3:"发布日期：2020年01月13日",
            },
            {
                href:'https://mp.weixin.qq.com/s/P3hzYkmBb_z-QLh13nkJnA',
                img:"5.jpg",
                t1:"荣誉 | 旺翔传媒荣获年度TOP15数字营销公司和TMA年度最佳移动营销数据平台",
                t2:"在2019年接近尾声之际，旺翔传媒荣获2019年度TOP15数字营销公司和TM年度最佳移动营销数据平台两项大奖",
                t3:"发布日期：2019年12月18日",
            },
            {
                href:'https://mp.weixin.qq.com/s/HHEMWTMPL_qLv4i7dJXRww',
                img:"4.jpg",
                t1:"荣誉 | 旺翔传媒荣获上海市服务业引导资金和“中小企业服务机构”称号",
                t2:"近期，旺翔传媒双喜临门，不仅荣获上海市服务业引导资金，还荣获了“上海市中小企业服务机构”称号！",
                t3:"发布日期：2019年11月21日",
            },
            {
                href:'https://mp.weixin.qq.com/s/nXlvz2nuokMc8OHWQY_SlA',
                img:"3.jpg",
                t1:"锦绣中华，盛世华诞—旺翔传媒组织员工集体观看爱国电影《我和我的祖国》！",
                t2:"我和我的祖国锦绣中华 盛世华诞70载披荆斩棘70载砥砺奋进每一段攀登的日子每一个跋涉的脚步记录着我们奋斗的历",
                t3:"发布日期：2019年10月22日",
            },
            {
                href:'https://mp.weixin.qq.com/s/Zl4wImoiT4ojai_Ml5yKKQ',
                img:"2.jpg",
                t1:"荣誉 | 旺翔传媒荣获“上海市和谐劳动关系达标企业”称号",
                t2:"8月16日，旺翔传媒被授予“上海市和谐劳动关系达标企业”荣誉称号",
                t3:"发布日期：2019年08月16日",
            },
            {
                href:'https://mp.weixin.qq.com/s/XLKcFe6L__iJNi_38OWUDA',
                img:"1.jpg",
                t1:"热烈欢迎上海市中小企业发展中心领导莅临我司参观指导",
                t2:"上海市经信委中小企业发展服务中心主任葛东波一行领导走访旺翔传媒，对旺翔传媒进行实地参观指导",
                t3:"发布日期：2019年07月26日",
            },
            {
                href:'https://mp.weixin.qq.com/s/AbSv2ZcZT3kVA6zNgPUolw',
                img:"0.jpg",
                t1:"热烈欢迎江都区领导一行莅临我司参观指导",
                t2:"热烈欢迎江都区委常委葛智勇、江都商会会长焦俊祥领导一行带队走访旺翔传媒",
                t3:"发布日期：2019年07月19日",
            },
            {
                href:'https://mp.weixin.qq.com/s/5sTZT0ie3q8o9pBeLcj3_Q',
                img:"news1.png",
                t1:'热烈欢迎普陀区工商联一行领导莅临旺翔数科参观指导',
                t2:'昨天，普陀区工商联领导李书记带队走访旺翔数科，对旺翔数科进行实地参观调研，了解企业近期经营发展情况，与旺翔数科董事长王正旺进行座谈交流。董事长王正旺表达了诚挚的欢迎与感谢并亲自接待。',
                t3:'发布日期：2019年06月18日',
            },
            {
                href:'https://mp.weixin.qq.com/s/VMRUNKiz3oh2JIJEuASXcw',
                img:"news2.png",
                t1:'热烈欢迎江都商会一行领导莅临旺翔数科参观指导',
                t2:'近日，江都商会领导走访商会成员企业，会长焦俊祥、秘书长李一峰带队走访由副会长王正旺创办的旺翔数科，对旺翔数科进行实地参观走访，了解企业近期经营发展情况，与旺翔数科董事长王正旺进行座谈交流。董事长王正旺表达了诚挚的欢迎与感谢并亲自接待。',
                t3:'发布日期：2019年05月26日',
            },
            {
                href:'https://mp.weixin.qq.com/s/QG6AI9jUPdzHhj7yQBw1pA',
                img:"news3.png",
                t1:'荣誉|旺翔数科荣获金鼠标两项大奖',
                t2:'点拾成金，风向十年。2019年5月14日-15日，素有“数字营销风向标”美誉的金鼠标国际数字营销节在北京举行。在此次峰会上，旺翔数科凭借卓越的数字营销能力和创新营销思路，在众多优秀企业中脱颖而出，荣获金鼠标两项大奖。',
                t3:'发布日期：2019年05月15日',
            },
            {
                href:'https://mp.weixin.qq.com/s/aAk1SN6mt2snFDaYLMQw1g',
                img:"news4.png",
                t1:'闹！元！宵！',
                t2:'元宵节到啦！情人节不是我的节日,元宵节谁也不能阻止我奔向汤圆！',
                t3:'发布日期：2019年02月19日',
            },
            {
                href:'https://mp.weixin.qq.com/s/fwn3aQE4prhq0SUqDFH7Yg',
                img:"news5.png",
                t1:'分享 | 刘润的10条工作建议：刷新你的认知，还有点扎心！',
                t2:'在年轻人眼中，公司是新鲜又陌生的地方。充满困惑，有了问题也不知道该问谁，有时候也不敢问。即使问了，有些话你也未必完全理解。10句话送给所有在职场上厮杀的年轻人。也许会刷新你的认知，甚至有些话略显扎心。希望他们能少走一些弯路，永远保持激情和梦想，在未知中拼杀出一条自由的坦途。',
                t3:'发布日期：2019年02月12日',
            },
            {
                href:'https://mp.weixin.qq.com/s/nmzsXnIpr1ltW4kabLfcPA',
                img:"news6.png",
                t1:'生日会 | 给大家介绍一下，这是我们的生日会！',
                t2:'生活需要仪式感，每一个特别的日子都值得去纪念，旺翔数科在过去的一年里，共举办了12个生日会，为所有旺翔的小伙伴都过了一个温暖的生日，今天，旺翔数科举行了2019年的第一个生日会，为1月出生的四位小伙伴庆生',
                t3:'发布日期：2019年01月23日',
            },
            {
                href:'https://mp.weixin.qq.com/s/Q6S54UvZ4YTNzO5BC8do8A',
                img:"news7.png",
                t1:'上海市文化市场执法总队领导带队调研旺翔数科',
                t2:'12月26日，上海市文化市场执法总队一行领导到上海旺翔数字科技股份有限公司进行实地参观走访，了解企业近期经营发展情况，与旺翔数科董事长王正旺进行座谈交流。董事长王正旺表达了诚挚的欢迎与感谢并亲自接待。',
                t3:'发布日期：2018年12月28日',
            },
            {
                href:'https://mp.weixin.qq.com/s/N7gFT8Dkxpzag9GpevcqTw',
                img:"news8.png",
                t1:'荣誉 | 恭喜旺翔数科斩获2018DMAA中国数字营销大赛两项大奖',
                t2:'12月21日晚，2018DMAA中国数字营销大奖颁奖典礼在上海举办，旺翔数科凭借在数字营销行业的卓越贡献，从众多企业中脱颖而出',
                t3:'发布日期：2018年12月24日',
            },
            {
                href:'https://mp.weixin.qq.com/s/JbDOG5cCXGn8KYgw4nIUSQ',
                img:"news9.png",
                t1:'荣誉 | 旺翔数科参加“瞪羚企业”授牌仪式并荣获年度优秀企业奖',
                t2:'12月18日，上海市普陀区科学技术委员会在普陀区举办了“支持民营科技企业在行动“系列活动启动仪式暨潜力科技企业入库培育名单发布会议。会上，顾军副区长、陈宏凯处长为入库潜力科技企业颁发荣誉证书并授牌，旺翔数科荣获“瞪羚企业”荣誉称号。',
                t3:'发布日期：2018年12月20日',
            },
            {
                href:'https://mp.weixin.qq.com/s/UV9yRuj2xD5Khrw2XaLMpA',
                img:"news10.png",
                t1:'区委常委、宣传部部长郝炳权带队调研旺翔数科',
                t2:'11月27日上午，区委常委、宣传部部长郝炳权到上海旺翔数字科技股份有限公司考察指导，了解企业近期经营发展情况，与旺翔数科董事长王正旺进行座谈交流。董事长王正旺表达了诚挚的欢迎与感谢并亲自接待。',
                t3:'发布日期：2018年12月03日',
            },
            {
                href:'https://mp.weixin.qq.com/s/HifZ5tGJDRXySFE6vJzzpw',
                img:"news11.png",
                t1:'上海市高院、普陀区法院领导在旺翔数科召开民营企业发展调研座谈会',
                t2:'11月30日下午，上海市高院党组成员、政治部主任陆卫民、普陀法院党组书记、院长王朝晖等一行人前往普陀区天地软件园，在旺翔数科召开调研座谈会，重点围绕民营企业的发展情况开展调研，就政法机关如何更好地保障和服务民营企业健康发展听取意见建议。天地软件园多家企业参与此次会议。',
                t3:'发布日期：2018年11月30日',
            },
            {
                href:'https://mp.weixin.qq.com/s/d31o8GYyh4QJbUI9yIL5wg',
                img:"news12.png",
                t1:'恭贺 | 旺翔数科成功入库普陀区潜力科技企业培育库',
                t2:'2018年7月，上海旺翔数字科技股份有限公司申报普陀区潜力科技企业入库培育项目，经过普陀区科委严格审核，成功获得普陀区潜力科技企业入库培育的机会。',
                t3:'发布日期：2018年11月29日',
            },
            {
                href:'https://mp.weixin.qq.com/s/W5bhyl3_gc_M8wEfmZ9K3Q',
                img:"news13.png",
                t1:'GMGC·成都·2018 第七届全球移动游戏开发者大会圆满举办',
                t2:'由全球移动游戏联盟（GMGC）主办的第七届全球游戏开发者大会暨天府奖盛典（简称：GMGC·成都·2018）于2018年11月5日-6日在成都首座万豪酒店盛大召开，本届大会以“恋游戏·游戏链”为主题。大会定向邀请1000+来自全球各地的优秀游戏开发者，3000+专业观众参会交流，此外还将联合100+行业媒体全程关注与报道此次盛会！',
                t3:'发布日期：2018年11月06日',
            },
            {
                href:'https://mp.weixin.qq.com/s/UntdWEDcNeUI42ifTKmVWA',
                img:"news14.png",
                t1:'今天开幕！ 首届进博会，都有哪些“不一般”？',
                t2:'今天，首届中国国际进口博览会开幕！这是世界上第一个以进口为主题的国家级展会，吸引了来自全球的众多目光。首届进博会，有来自五大洲的172个国家、地区和国际组织参与其中，向世界展示发展成就和国家形象，参展企业达到的3600多家。为何进博会如此受关注，它到底都有哪些“不一般”？',
                t3:'发布日期：2018年11月05日',
            },
            {
                href:'https://mp.weixin.qq.com/s/SSL1jS-N47L7BK6H1QdnAA',
                img:"news15.png",
                t1:'良心推荐 | 国庆出行，哪里旅游最便宜？人还少？',
                t2:'“工作原因去过几次，免签，十一确实人少，根本不需要排队，环境也好。最主要没强制性消费还全面覆盖WIFI，吃的喝的都很习惯，确实不错，良心推荐。”我今年就还是去这了。',
                t3:'发布日期：2018年09月29日',
            },
            {
                href:'https://mp.weixin.qq.com/s/XFAriDUMZEju1G3M7VZArg',
                img:"news16.png",
                t1:'品牌+传播——数字时代的营销论',
                t2:'随着互联网时代的不断发展，传统营销已经逐渐转型成数字营销，数字营销已经成为了营销的基本动作。不难发现，没有任何一个营销活动不和数字界面相挂钩。对于品牌多样化的今天，个性化的消费成为了一大热门的话题，同时也会决定企业是否可以在市场中抢得先机的必要前提。2018年，数字营销从内容、流量、渠道多个角度都面临着更为复杂的要求。',
                t3:'发布日期：2018年09月03日',
            },
            {
                href:'https://mp.weixin.qq.com/s/qqZehX-ar-FyqnDf6HUDHA',
                img:"news17.png",
                t1:'数字营销时代所需的三种人才',
                t2:'所谓数字营销，就是指借助于互联网络、电脑通信技术和数字交互式媒体来实现营销目标的一种营销方式。它使用数字传播渠道来推广产品和服务的实践活动，从而以一种及时、相关、定制化和节省成本的方式与消费者进行沟通。数字营销将尽可能地利用先进的计算机网络技术，以最有效、最省钱地谋求新的市场的开拓和新的消费者的挖掘。',
                t3:'发布日期：2018年08月29日',
            },
            {
                href:'https://mp.weixin.qq.com/s/jd6wqGmG70lVuYaYpsfS4w',
                img:"news18.png",
                t1:'生日会 | 狮子座与处女座的专属生日party！',
                t2:'"八月，汇集着火的色彩,八月，洋溢着热情、奔放,八月的骄阳，绽放整个季节最耀眼的光芒,正是这样的季节赋予了出生者的特质,阳光自信、果敢胆略"',
                t3:'发布日期：2018年08月24日',
            },
            {
                href:'https://mp.weixin.qq.com/s/97VN0PiCcbRmwIbmbSqNTQ',
                img:"news19.png",
                t1:'旺翔数科举办“百度原生KA渠道游戏行业沙龙”聚会，行业共探营销新思路',
                t2:'据游戏工委权威报告显示，2017年中国移动游戏市场实际销售收入达到1161.2亿元，同比增长41.7%。2017年中国移动游戏用户规模达到5.54亿人，同比增长4.9%。巨大的市场吸引各大游戏开发者和游戏发行商加入角逐，渠道流量竞争异常激烈。游戏开发者积极与游戏发行商展开合作，共同构建全新的独立游戏生态，已然是新时代的发展趋势和需求。',
                t3:'发布日期：2018年08月15日',
            },
            {
                href:'https://mp.weixin.qq.com/s/dcSLFohALBI8w-SEsbWPxg',
                img:"news20.png",
                t1:'旺翔数科再度携手中国银行助力公益扶贫事业',
                t2:'为弘扬爱心，向社会传递正能量，中国银行携手旺翔数科继去年的“苹果红了”公益助农活动后，近日再度合作开展“小米来了”公益助农活动，助力精准扶贫。',
                t3:'发布日期：2018年08月13日',
            },
            {
                href:'https://mp.weixin.qq.com/s/ZeNlY5LbmRS_TPyxc5BVgA',
                img:"news21.png",
                t1:'精彩纷呈：2018ChinaJoy旺翔数科展台完美落幕！',
                t2:'在经历了四天人山人海的热潮后，2018年第十六届ChinaJoy在上海新国际博览中心落幕，旺翔数科2018ChinaJoyBTOB展台亦完美收官。',
                t3:'发布日期：2018年08月07日',
            },
            {
                href:'https://mp.weixin.qq.com/s/7WAFSmzMemEdi-AZdVTAcQ',
                img:"news22.png",
                t1:'恭贺|旺翔数科荣获2018年上海市文化创意产业发展财政扶持资金',
                t2:'2018年，上海旺翔数字科技股份有限公司将“旺翔—全球移动数字营销及海外发行平台”作为申报项目，经过2018年上海市促进文化创意产业发展财政扶持资金专项资金申报、评审等程序，成功获得2018年上海市促进文化创意产业发展财政扶持资金。',
                t3:'发布日期：2018年08月02日',
            },
            {
                href:'https://mp.weixin.qq.com/s/SlUvPjEK8XqshcglAIPDdg',
                img:"news23.png",
                t1:'上市后市值疯涨40%，创始人身价超刘强东：永远不要轻视一家总在亏损的公司！',
                t2:'不管你有多么的矛盾、疑惑、无奈、不安，拼多多昨晚在美股成功上市的消息还是如潮水一样无情冲刷了我们的朋友圈。',
                t3:'发布日期：2018年07月27日',
            },
        ],
        num:1
      };
    },
    components: {  },
    mounted() {
    },
    methods: {

    currentChange(i){
        this.num = i
    },
      toNum(num){
        this.num = num
      },
      toPath(path,target){
        if(!path){
          return
        }
        if(path.includes(this.OUT_URL)){
          window.open(path, '_blank');
        }else{
          this.$router.push({path:path,query: {}})
        }
      }
    },
  };
  </script>
  <style lang='less' src="./index.less"  >
  </style>