<template>
  <div>
    <section class="banner">
      <div class="banner-content">
        <div class="container">
          <div class="contents-main">
            <h2 class="wow fadeInDown media-txt animated" data-wow-duration="1000ms" data-wow-delay="0.2s" style="visibility: visible;-webkit-animation-duration: 1000ms; -moz-animation-duration: 1000ms; animation-duration: 1000ms;-webkit-animation-delay: 0.2s; -moz-animation-delay: 0.2s; animation-delay: 0.2s;">汇聚全球·助力营销</h2>
            <h2 class="wow fadeInUp bot-txt animated" data-wow-duration="1000ms" data-wow-delay="0.3s" style="visibility: visible;-webkit-animation-duration: 1000ms; -moz-animation-duration: 1000ms; animation-duration: 1000ms;-webkit-animation-delay: 0.3s; -moz-animation-delay: 0.3s; animation-delay: 0.3s;">国内领先的数字营销服务平台</h2>
            <a  data-lity="" @click="openDialog" data-wow-duration="1000ms" data-wow-delay="0.3s" class="wow bounceIn tabb-btn animated" style="visibility: visible;-webkit-animation-duration: 1000ms; -moz-animation-duration: 1000ms; animation-duration: 1000ms;-webkit-animation-delay: 0.3s; -moz-animation-delay: 0.3s; animation-delay: 0.3s;">立即了解</a>
          </div>
        </div>
      </div>
      <div class="banner-container">
        <div class="banner-animation">
          <div class="b-a-top">
            <div class="search-chart">
              <div class="s-c-hotmobi">
                <div class="c-chart-one"><img src="../../assets/img/banner/solidline.png" alt="custom chart"></div>
                <div class="c-chart-cirlce"><div class="c-c-pulse"></div><div class="c-c-dot"></div></div>
                <div class="c-chart-two"><img src="../../assets/img/banner/dotline1.png" alt="custom chart"></div>
                <div class="c-chart-cirlce c-c-second"><div class="c-c-pulse"></div><div class="c-c-dot"></div></div>
                <div class="c-chart-three"><img src="../../assets/img/banner/dotline2.png" alt="custom chart"></div>
                <div class="c-chart-cirlce c-c-third"><div class="c-c-pulse"></div><div class="c-c-dot"></div></div>
              </div> 
              <div class="hotmobi-text">
                <div class="hotmobi-box"></div>
                <div class="hotmobi-box"></div>
                <img src="../../assets/img/banner/hotmobitxt.png" class="pie-htmobi-img" alt="hotmobi text">
              </div>
            </div>
            <div class="search-sec">
              <div class="search-main">
                <div class="search-main-title">DO A QUICK SEARCH</div>
              </div>
              <div class="s-m-content">
                <div class="sm-c-left">
                  <p>上海旺翔数字科技股份有限公司</p>
                  <p>定制化广告投放策略</p>
                  <p>丰富的媒体、广告资源</p>
                  <p>成熟、稳定的广告分发系统，使广告主放心、开发者舒心</p>
                  <p>支持CPM、CPC、CPA、CPD等常见的计费模式</p>
                </div>
                <div class="sm-c-right">
                  <p>提供全方位流量变现解决方案</p>
                  <p>专业的运营团队，7*24小时为客户保驾护航</p>
                  <p>低廉的成本，高效、可观的收益</p>
                  <p>Banner、插屏、开屏、信息流、激励视频等丰富的广告形式</p>
                  <p>多年技术积累，拥有完善的防作弊以及流量跟踪机制</p>
                </div>
              </div>
            </div>
          </div>
          <div class="b-a-middle">
            <div class="charging-two">
              <div class="bab-power">
                <div class="charging">
                  <div class="outer">
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="inner"></div>
                        <div class="flash ion-ios-bolt fadeIn"></div>
                    </div>
                </div>
              </div>
            </div>
            <div class="ba-tabbs">
              <!-- <div class="tabbs-list"><img src="../../assets/img/banner/banner-tabb/ADX.png" alt="ADX menu"></div> -->
              <div class="tabbs-list"><img src="../../assets/img/banner/banner-tabb/CPD.png" alt="CPD menu"></div>
              <div class="tabbs-list"><img src="../../assets/img/banner/banner-tabb/DSP.png" alt="DSP menu"></div>
              <div class="tabbs-list"><img src="../../assets/img/banner/banner-tabb/SSP.png" alt="SSP menu"></div>
            </div>
            <div class="ba-lines-anim">
              <div class="ba-anim-three"><img src="../../assets/img/banner/ba-mid-three.png" alt="ba-anim-pie"></div>
              <div class="ba-anim-two"><img src="../../assets/img/banner/ba-mid-two.png" alt="ba-anim-pie"></div>
              <div class="adunite-text "><img src="../../assets/img/banner/adunitetxt.png" alt="adunite text"><p class="logo-txt">www.adunite.com</p></div>
              <div class="ba-anim-four">
                <div class="baf-anim-main notes-lines"><div class="notes-common notes-one"></div></div>
                <img src="../../assets/img/banner/ba-mid-one.png" alt="ba-anim-pie">
              </div>
            </div>
            <div class="ba-pie-main">
              <div class="ba-pie-sec">
                <div class="ba-anim-one"><img src="../../assets/img/banner/ba-mid-four.png" alt="pie-pointer"></div>
                <div class="ba-pie-content">
                  <div class="piea" style="position: relative; border-radius: 50%; width: 320px; height: 320px;">
                    <svg width="320" height="320" viewBox="0 0 100 100" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <circle cx="50" cy="50" r="42" class="piea-stroke disk-pieaStroke" style="fill: transparent; stroke: #55668b; stroke-width: 8; stroke-dashoffset: 66; stroke-dasharray: 20 243 "></circle>
                    </svg>
                    <div class="percent" style="position: absolute; left: 50%; top: 50%;transform: translate(-50%, -50%); font-size: 3rem; font-weight: 700; color: #365b74">{{num97}}%</div>
                  </div>
                </div>
              </div>
              <div class="piea-border"><div class="piea-inner"></div></div>
            </div>
          </div>
          <div class="b-a-bottom">
            <h2 class="ba-b-head">To Be Determined</h2>
            <div class="ba-b-left">
              <div class="bab-l-first">
                <div class="bab-f-top">
                  <div class="bab-power">
                    <div class="battery"></div>
                    <div class="charging-one">
                      <div class="outer">
                            <div class="inner"></div>
                            <div class="inner"></div>
                            <div class="inner"></div>
                            <div class="inner"></div>
                            <!-- <div class="inner"></div> -->
                            <div class="flash ion-ios-bolt fadeIn"></div>
                        </div>
                    </div>
                  </div>
                </div>	
                <div class="bab-f-bottom">
                  <div class="b-counter">{{ num90 }}</div>
                </div>
              </div>
              <div class="bab-l-second">
                <div class="bab-progress">
                    <div class="label" data-count="75%">{{num75}}%</div>
                    <div class="line"><span :style="{width: ''+num75+'%'}"></span></div>
                  </div>
                  <div class="bab-binary">
                    <p>10011001001</p>
                    <p>11000001101001010101010100001</p>
                    <p>110100010010011110</p>
                    <p>1010101111</p>
                  </div>
                  <!-- <div class="bab-binary">
                    <div class="binary-code">
                      <p>10011001001</p>
                      <p>11000001101001010101010100001</p>
                      <p>110100010010011110</p>
                      <p>1010101111</p>
                    </div>
                  </div> -->
              </div>
              <div class="bab-l-third">
                <div class="babl-t-top disk">
                  <div class="disk-main">
                    <div class="dm-rect rect-top"></div>
                  </div>
                </div>
                <div class="babl-t-bottom disk-reverse">
                  <div class="disk-main">
                    <div class="dm-rect rect-bottom"></div>
                  </div>
                </div>
              </div>
              <div class="bab-l-four">
                <div class="bab-f-notes">
                  <div class="notes-lines"><div class="notes-common notes-one"></div></div>
                  <div class="notes-lines"><div class="notes-common notes-two"></div></div>
                  <div class="notes-lines"><div class="notes-common notes-three"></div></div>
                  <div class="notes-lines"><div class="notes-common notes-four"></div></div>
                  <div class="notes-lines"><div class="notes-common notes-five"></div></div>
                  <div class="notes-lines"><div class="notes-common notes-six"></div></div>
                  <div class="notes-lines"><div class="notes-common notes-seven"></div></div>
                  <div class="notes-lines"><div class="notes-common notes-eight"></div></div>
                </div>
              </div>
              <div class="bab-l-five">
                <div class="f-box" :class="{fBoxActive: t1 != 0}" ></div>
                <div class="f-box" :class="{fBoxActive: t2 != 0}"></div>
                <div class="f-box" :class="{fBoxActive: t3 != 0}"></div>
                <div class="f-box" :class="{fBoxActive: t4 != 0}"></div>
                <div class="f-box" :class="{fBoxActive: t5 != 0}"></div>
                <div class="f-box" :class="{fBoxActive: t6 != 0}"></div>
              </div>
              <div class="bab-l-six">
                <div class="ls-top">
                  <div class="ls-circle"></div>
                  <div class="ls-circle"></div>
                </div>
                <div class="ls-middle">
                  <div class="ls-counter-two"><div class="b-counter-two">{{ num24357 }}</div></div>
                  <div class="ls-bot-line"></div>
                  <div class="ls-bot-line ls-new-two"></div>
                  <div class="ls-bot-line ls-new-three"></div>
                  <div class="ls-bot-line ls-new-four"></div>
                </div>
                <div class="ls-bottom">
                  <div class="last-line-one"></div>
                  <div class="last-line-two"></div>
                </div>
              </div>
            </div>
            <div class="ba-b-right">
              <div class="r-progress r-p-one">
                <div class="r-progress-bar"></div>
              </div>
              <div class="r-progress r-p-two">
                <div class="r-progress-bar"></div>
              </div>
              <div class="r-progress r-p-three">
                <div class="r-progress-bar"></div>
              </div>
              <div class="r-progress r-p-four">
                <div class="r-progress-bar"></div>
              </div>
              <div class="r-progress r-p-five">
                <div class="r-progress-bar"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 我们的平台 -->
    <section id="portfolios" class="portfolios">
      <div class="container">
        <div class="section-header">
          <h2 class="section-title wow fadeIn animated" data-wow-duration="100ms" data-wow-delay="0.1s" style="visibility: visible;-webkit-animation-duration: 100ms; -moz-animation-duration: 100ms; animation-duration: 100ms;-webkit-animation-delay: 0.1s; -moz-animation-delay: 0.1s; animation-delay: 0.1s;">我们的平台</h2>
          <p class="section-subtitle">全方位数据分析平台，让每一个成员都能充分了解并利用他们的数据</p>
        </div>
        <div class="row port-tab">
          <div class="toggle-tabb">
            <div class="tabs">
              <div class="tab" :class="{active:num == 1}" @click="toNum(1)">SSP平台</div>
              <div class="tab" :class="{active:num == 2}" @click="toNum(2)">DSP平台</div>
              <div class="tab" :class="{active:num == 3}" @click="toNum(3)">ADX平台</div>
              <div class="tab" :class="{active:num == 4}" @click="toNum(4)">CPD平台</div>
            </div>
            <div class="panels">
              <div class="panel" v-show="num == 1">
                <div class="panel-left">
                  <h2>SSP平台</h2>
                  <h3>供应方平台</h3>
                  <p>整合多方优质的媒体资源，超高效资源利用、超精准资源匹配的强大供应系统。</p>
                  <a @click="toPath('/ssp')" class="tabb-btn"><span>了解更多</span></a>
                </div>
                <img src="../../assets/img/advertise/tab-inner1.png" alt="cpd">
              </div>
              <div class="panel" v-show="num == 2">
                <div class="panel-left">
                  <h2>DSP平台</h2>
                  <h3>广告需求</h3>
                  <p>专注于数据仓储和挖掘和分析，运用RTB和大数据算法使得广告投放受众更精准，效果更优。</p>
                  <a href="http://dsp.adunite.com/" target="_blank" class="tabb-btn"><span>了解更多</span></a>
                </div>
                <img src="../../assets/img/advertise/tab-inner2.png" alt="cpd">
              </div>
              <div class="panel" v-show="num == 3">
                <div class="panel-left">
                  <h2>ADX平台</h2>
                  <h3>交易管理</h3>
                  <p>针对每次展示进行实时竞价，代理机构和第三方技术可通过实时竞价的方式购买优质广告资源。</p>
                  <a href="http://adx.adunite.com/" target="_blank" class="tabb-btn"><span>了解更多</span></a>
                </div>
                <img src="../../assets/img/advertise/tab-inner3.png" alt="cpd">
              </div>
              <div class="panel" v-show="num == 4">
                <div class="panel-left">
                  <h2>CPD平台</h2>
                  <h3>媒体服务平台</h3>
                  <p>覆盖主流应用市场，一手管理所有平台，自主投放，自由控制，为您节省更多时间。</p>
                  <a @click="toPath('/cpd')" class="tabb-btn"><span>了解更多</span></a>
                </div>
                <img src="../../assets/img/advertise/tab-inner4.png" alt="cpd">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 合作案例 -->
    <section id="adxtabaner" class="adxtabaner">
      <!-- <div class="overlay"></div> -->
      <div class="container">
        <div class="section-header">
          <h2 class="section-title wow fadeIn animated" data-wow-duration="100ms" data-wow-delay="0.1s" style="visibility: visible;-webkit-animation-duration: 100ms; -moz-animation-duration: 100ms; animation-duration: 100ms;-webkit-animation-delay: 0.1s; -moz-animation-delay: 0.1s; animation-delay: 0.1s;">合作案例</h2>
          <p class="section-subtitle">针对行业特征及细分业务场景，深度引流</p>
        </div>
        <!-- Slider starts -->
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="flexslider">
                <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img src="../../assets/img/slider/slide1.png" class="d-block w-100" alt="">
                    </div>
                    <div class="carousel-item">
                      <img src="../../assets/img/slider/slide2.png" class="d-block w-100" alt="">
                    </div>
                    <div class="carousel-item">
                      <img src="../../assets/img/slider/slide3.png" class="d-block w-100" alt="">
                    </div>
                    <div class="carousel-item">
                      <img src="../../assets/img/slider/slide4.png" class="d-block w-100" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>			
      <!-- Slider ends -->
      </div>
    </section>
    <!-- 合作伙伴 -->
    <section id="clients" class="clients">
      <div class="container">
        <div class="section-header">
          <h2 class="section-title">合作伙伴</h2>
          <p class="section-subtitle">更多企业选择与旺翔合作</p>
        </div>
        <div class="client-info-top">
          <div class="client-item-wrapper">
            <img src="../../assets/img/clients/client1.png" alt="">
          </div>
          <div class="client-item-wrapper">
            <img src="../../assets/img/clients/client2.png" alt="">
          </div>
          <div class="client-item-wrapper">
            <img src="../../assets/img/clients/client3.png" alt="">
          </div>
          <div class="client-item-wrapper">
            <img src="../../assets/img/clients/client4.png" alt="">
          </div>
          <div class="client-item-wrapper">
            <img src="../../assets/img/clients/client5.png" alt="">
          </div>
          <div class="client-item-wrapper margin-bottom-remover">
            <img src="../../assets/img/clients/client6.png" alt="">
          </div>
          <div class="client-item-wrapper margin-bottom-remover">
            <img src="../../assets/img/clients/client7.png" alt="">
          </div>	
          <div class="client-item-wrapper margin-bottom-remover">
            <img src="../../assets/img/clients/client8.png" alt="">
          </div>
          <div class="client-item-wrapper margin-bottom-remover">
            <img src="../../assets/img/clients/client9.png" alt="">
          </div>
          <div class="client-item-wrapper margin-bottom-remover">
            <img src="../../assets/img/clients/client10.png" alt="">
          </div>
        </div>
      </div>	
    </section>
    <!-- 立即注册 -->
    <section id="i-register" class="i-register">
      <div class="container">
        <div class="i-r-main">
          <h2>如需进一步了解Adunite·旺翔，可立即注册 我们会尽快安排专业人员与您沟通，帮助您快速快速提升流量变现</h2>
          <div class="tabb-btn" @click="openDialog">立即注册</div>
        </div>
      </div>
    </section>
    <el-dialog width="940px" :visible="dialogVisible" :show-close="false" :before-close="handleClose">
      <InlinePops></InlinePops>
    </el-dialog>
  </div>
</template>

<script>
import InlinePops from "../../components/inlinePops/inlinePops.vue";
export default {
  data() {
    return {
      dialogVisible:false,
      num:1,
      num97:0,
      num90:0,
      num75:0,
      num24357:0,
      t1:0,
      t2:1,
      t3:0,
      t4:2,
      t5:1,
      t6:0,
      strokeDasharrayX:20,
      strokeDasharrayY:243
    };
  },
  components: { InlinePops },
  mounted() {
    let time1 = setInterval(()=>{
      this.num97 = this.num97 + 1
      if(this.num97 == 97){
        clearInterval(time1)
      }
    },50)
    let time2 = setInterval(()=>{
      this.num90 = this.num90 + 1
      if(this.num90 == 90){
        clearInterval(time2)
      }
    },60)
    let time3 = setInterval(()=>{
      this.num75 = this.num75 + 1
      if(this.num75 == 75){
        clearInterval(time3)
      }
    },80)
    let time4 = setInterval(()=>{
      this.num24357 = this.num24357 + 1
      if(this.num24357 == 999999){
        clearInterval(time4)
      }
    },30)
    setInterval(()=>{
      this.t1 = (Math.floor(Math.random() * 100) + 1) % 2
      this.t2 = (Math.floor(Math.random() * 100) + 1) % 2
      this.t3 = (Math.floor(Math.random() * 100) + 1) % 2
      this.t4 = (Math.floor(Math.random() * 100) + 1) % 2
      this.t5 = (Math.floor(Math.random() * 100) + 1) % 2
      this.t6 = (Math.floor(Math.random() * 100) + 1) % 2
    },2000)
    
  },
  methods: {
    openDialog(){
      this.dialogVisible = true
    },
    handleClose(done){
        this.dialogVisible = false
        done()
    },
    toNum(num){
      this.num = num
    },
    toPath(path,target){
      if(!path){
        return
      }
      if(path.includes(this.OUT_URL)){
        window.open(path, '_blank');
      }else{
        this.$router.push({path:path,query: {}})
      }
    }
  },
};
</script>
<style lang='less' src="./index.less"  >
</style>