import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Vant from 'vant';
// import 'vant/lib/index.css';
// import './plugins/vant.js'
import './plugins/element.js'
import 'bootstrap/dist/css/bootstrap.css' //引用bootstrap的样式
import 'bootstrap/dist/js/bootstrap.min.js' //引用bootstrap的js


Vue.config.productionTip = false
Vue.prototype.OSS_STATE = process.env.VUE_APP_OSS_URL ? true : false;
Vue.prototype.OUT_URL = process.env.VUE_APP_OUT_URL
// Vue.use(Vant);

router.afterEach((to, from)=> {
  document.title = '旺翔数科-' + to.meta.title
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
