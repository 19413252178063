<template>
    <div>
        <div class="loan-first lan-cn">
            <div class="wrapper">
                <div class="first-title">
                    <h2>SSP平台-应用流量变现专家</h2>
                    <p>整合多方优质的媒体资源，超高效资源利用、超精准资源匹配的强大供应系统。</p>
                </div>
            </div>
        </div>
        <div class="w_per_100 index_bar bg_white">
            <div class="index_tit" style=" padding-bottom: 60px;">
                <h1>高效自助的接入方式</h1>
            </div>
            <div class="index_cont_box space_between">
                <div class="index03_item">
                    <img src="../../assets/img/sdk.png">
                    <h4>旺翔SDK</h4>
                    <p>　　适用于移动开发者，它能够支持您的应用进行丰富样式的广告变现，您只需要进行简单配置，就可以在您的应用中显示定制的广告。</p>
                </div>
                <div class="index03_item">
                    <img src="../../assets/img/api.png">
                    <h4>旺翔API</h4>
                    <p>　　强大的数据传输功能，轻松实现与其他组件间的交互，可依据平台需求建立极具个性化与智能化的广告投放模式，精准触及用户。</p>

                </div>
                <div class="index03_item">
                    <img src="../../assets/img/js.png">
                    <h4>旺翔JS</h4>
                    <p>　　不依赖操作系统，直接通过浏览器加载运行，可为网页带来多样化的动态模式，增强广告浏览价值，提升投放优势。</p>

                </div>
            </div>
        </div>
        <div class="loan-second loan-p clearfix" style="margin-bottom: 40px;">
            <div class="wrapper">
                <div class="loan-title">我们的优势</div>
                <div class="my-service">
                    <div class="my-service-item">
                        <img src="../../assets/img/service-17-yj.png" alt="">
                        <h4>广告库存管理</h4>
                        <span>灵活设置广告展示的位置、<br>频率和形式。</span>
                    </div>
                    <div class="my-service-item">
                        <img src="../../assets/img/service-15-rx.png" alt="">
                        <h4>多样化广告形式</h4>
                        <span>原生广告、横幅广告、<br>插屏广告、视频广告等。</span>
                    </div>
                    <div class="my-service-item">
                        <img src="../../assets/img/service-16-zy.png" alt="">
                        <h4>实时广告交易</h4>
                        <span>支持实时竞价（RTB）<br>和非实时竞价（Non-RTB）</span>
                    </div>
                    <div class="my-service-item">
                        <img src="../../assets/img/service-14-jz.png" alt="">
                        <h4>数据分析和优化</h4>
                        <span>提供全面的数据分析和优化工具，<br>深入了解应用流量和广告效果。</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="loan-second loan-p clearfix" style="margin-bottom: 140px;">
            <div class="wrapper">
                <div class="loan-title">我们的承诺</div>
                <div class="my-service my-best">
                    <div class="my-best-item">
                        <div class="hexagon">最</div>
                        <div class="info">
                            <h4>最大化收益</h4>
                            <span>致力于帮助您最大化<br>应用流量的商业价值。</span>
                        </div>
                    </div>
                    <div class="my-best-item">
                        <div class="hexagon">用</div>
                        <div class="info">
                            <h4>用户体验至上</h4>
                            <span>与广告主合作，确保广告投放<br>与用户体验的完美结合。</span>
                        </div>
                    </div>
                    <div class="my-best-item">
                        <div class="hexagon">数</div>
                        <div class="info">
                            <h4>数据保护与安全</h4>
                            <span>高度重视用户数据的保护，<br>确保数据不被滥用或泄露。</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w_per_100 index_bar bg_grey">
            <div class="index_tit" style="padding-bottom:60px;">
                <h1>加入我们，一起开启流量价值之旅</h1>
            </div>
            <div class="index06">
                <div class="btn" id="openCooperation" @click="tip" style="background-color: #3167ff;color: #fff;">开启合作</div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SSPPlatform',
  data() {
    return {
    };
  },
  components: {},
  
  watch: {
    $route: {
      handler: function(val, oldVal){
        this.path_state = val.fullPath
      },
      // 深度观察监听
      deep: true,
      immediate:true
    }
  },
  mounted() {

  },
  methods: {
    tip(){
        this.$message({
          message: '敬请期待！',
          type: 'success'
        });
    },
  },
};
  </script>
  
  <style lang='less' src="./index.less"  ></style>
  <style  src="./style.css"  ></style>