<template>
    <div>
        <div class="loan-first lan-cn">
            <div class="wrapper">
                <div class="first-title">
                    <h2>CPD广告投放</h2>
                    <p>依托海量优质流量资源，提供多平台的媒体投放策略，并利用大数据处理算法实现成本可控、效果客观、一键定投的智能媒体投放。</p>
                </div>
            </div>
        </div>
        <div class="loan-second loan-p clearfix">
            <div class="wrapper">
                <div class="loan-title">我们的服务</div>
                <div class="my-service">
                    <div class="my-service-item">
                        <img src="../../assets/img/service-14-jz.png" alt="">
                        <h4>精准定位用户</h4>
                        <span>通过关键词。时间等多种方式<br>定内用户，使推广更有效</span>
                    </div>
                    <div class="my-service-item">
                        <img src="../../assets/img/service-15-rx.png" alt="">
                        <h4>人性化操作</h4>
                        <span>自主修改投放价格，调控投放<br>位置，保作更简单</span>
                    </div>
                    <div class="my-service-item">
                        <img src="../../assets/img/service-16-zy.png" alt="">
                        <h4>专业数据统计</h4>
                        <span>多种专业统计数据报告，让推广<br>和优化更快捷高效</span>
                    </div>
                    <div class="my-service-item">
                        <img src="../../assets/img/service-17-yj.png" alt="">
                        <h4>预警反馈问题</h4>
                        <span>及时反馈投放问题，有效控制价格<br>策略，预防成本损失</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="loan-second loan-p clearfix">
            <div class="wrapper">
                <div class="loan-title">服务优势</div>
                <div class="my-service my-best">
                    <div class="my-best-item">
                        <div class="hexagon">多</div>
                        <div class="info">
                            <h4>多平台覆盖</h4>
                            <span>全面支持OPPO、VIVO、华为<br>百度、豌豆荚、应用宝等市场</span>
                        </div>
                    </div>
                    <div class="my-best-item">
                        <div class="hexagon">专</div>
                        <div class="info">
                            <h4>专业投放经验</h4>
                            <span>为多家广告投放代理商及天数<br>App开发者提供广告投放服务</span>
                        </div>
                    </div>
                    <div class="my-best-item">
                        <div class="hexagon">优</div>
                        <div class="info">
                            <h4>优质售后服务</h4>
                            <span>拥有顶级客户服务团队，为<br>您提供专人专属的服务体验</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="loan-four loan-p loan-t clearfix">
            <div class="wrapper">
                <div class="loan-title">常见问题</div>
                <div class="question-list">
                    <div class="question-item">
                        <div class="q">
                            Q：有哪些渠道可以投放？
                        </div>
                        <p class="a">
                            <em>A：</em>全面支持OPPO、VIVO、豌豆荚、百度、华为、百度、360、小米、应用宝等国内主流应用市场。
                        </p>
                    </div>
                    <div class="question-item">
                        <div class="q">
                            Q：在旺翔投放CPD有什么优势？
                        </div>
                        <p class="a">
                            <em>A：</em>我们提供了专业的数据统计报告，让投放效果更易于理解，另外提供了预警机制，有效地控制价格策略。
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        list:[
            
        ],
        num:1
      };
    },
    components: {  },
    mounted() {
    },
    methods: {

    currentChange(i){
        this.num = i
    },
      toNum(num){
        this.num = num
      },
      toPath(path,target){
        if(!path){
          return
        }
        if(path.includes(this.OUT_URL)){
          window.open(path, '_blank');
        }else{
          this.$router.push({path:path,query: {}})
        }
      }
    },
  };
  </script>
  <style lang='less' src="./index.less"  >
  </style>