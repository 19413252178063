<template>
  <div>
    <div id="google-map" v-show="state">
      <div class="map-main"></div>
    </div>
    <footer id="footer" class="footer">
      <div class="toggle-map">
        <a  class="map-icon wow pulse animated" @click="map" data-wow-iteration="infinite" data-wow-duration="500ms" style="visibility: visible;-webkit-animation-duration: 500ms; -moz-animation-duration: 500ms; animation-duration: 500ms;-webkit-animation-iteration-count: infinite; -moz-animation-iteration-count: infinite; animation-iteration-count: infinite;">
          <i class="lnr lnr-map"></i>
        </a>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="footer-top">
              <div class="footer-left toggle-tabb">
                <h2>关于我们</h2>
                <a  @click="toPath('/about')">公司简介</a>
                <a  @click="toPath('/news')">新闻动态</a>
              </div>
              <div class="footer-middle">
                <h2>联系我们</h2>
                <p>电话：<span class="cell">400-8160-569</span></p>
                <p>邮箱：<span class="mail">Info@aduite.com</span></p>
                <p>地址：<span class="mail">上海市普陀区中江路879弄3栋303</span></p>
              </div>
              <div class="footer-middle f-m-right">
                <h2>合作方式</h2>
                <p>渠道合作</p>
                <p>广告主合作</p>
              </div>
              <div class="footer-right">
                <h2>微信公众号</h2>
                <a href="javascript:void(0)" class="qr-code"><img src="../../assets/img/scan/qr-code.png" alt="qr code"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="site-info">
        <p>© 2012-2023 上海旺翔数字科技股份有限公司 股票代码：835063 <a href="https://beian.miit.gov.cn" target="_blank">沪ICP备 15027696号</a> 沪网文4109-286</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
        state:false,
        path_state:''
    };
  },
  components: {},
  
  watch: {
    $route: {
      handler: function(val, oldVal){
        this.path_state = val.fullPath
      },
      // 深度观察监听
      deep: true,
      immediate:true
    }
  },
  mounted() {

  },
  methods: {
    map(){
      this.state = this.state ? false : true
    },
    toPath(path){
      if(!path){
        return
      }
      if(this.path_state == path){
        return false
      }
      if(path.includes(this.OUT_URL)){
        window.open(path, '_blank');
      }else{
        this.$router.push({path:path,query: {}})
      }
    }
  },
};
</script>

<style lang='less' >
.container {
    max-width: 1200px !important;
    position: relative;
}
.site-info {
    width: 100%;
    margin-top: 10px;
    padding: 20px 0 15px;
    float: left;
    background-color: #F7FAFE;
    p {
        color: #333;
        font-size: 13px;
    }
}
.footer {
    width: 100%;
    padding: 60px 0 0;
    position: relative;
    float: left;
    background: #F7FAFE;
    text-align: center;
    .toggle-map {
        height: 5px;
        width: 100%;
        background-color: #6D85F5;
        /* background-color: #6D85F5; */
        top: 0;
        bottom: 0px;
        text-align: center;
        position: absolute;
        z-index: 106;
        .map-icon {
            width: 50px;
            height: 50px;
            display: inline-block;
            -moz-border-radius: 100%;
            -webkit-border-radius: 100%;
            border-radius: 100%;
            background-color: #fff;
            border: #6D85F5 3px solid;
            position: relative;
            top: -46px;
            i {
                width: 44px;
                height: 44px;
                display: inline-block;
                line-height: 44px;
                text-align: center;
                font-size: 20px;
            }
        }
    }
    .footer-top {
        float: left;
        width: 100%;
        .footer-left {
            width: 9%;
            margin-right: 192.5px;
            float: left;
            text-align: left;
            border-right: 1px solid #fff;
            h2 {
                margin-bottom: 18px;
                color: #333;
                font-size: 24px;
            }
            a {
                margin-bottom: 12px;
                display: block;
                color: #333;
                font-size: 12px;
            }
        }
        .footer-left.toggle-tabb {
            width: auto;
        }
        .footer-right {
          width: 11%;
          float: left;
          h2 {
              margin-bottom: 18px;
              color: #333;
              font-size: 24px;
          }
          a{
            display: block;
            img{
              width: 100%;
            }
          }
        }
        .footer-middle {
            width: 22%;
            margin-right: 192.5px;
            padding: 0 0 0 0;
            float: left;
            text-align: left;
            h2 {
                margin-bottom: 18px;
                color: #333;
                font-size: 24px;
            }
            p,a {
                margin-bottom: 8px;
                margin-top: 8px;
                padding: 0;
                display: block;
                color: #333;
                font-size: 12px;
            }
        }
        .f-m-right {
            width: 9%;
        }
    }
}
#google-map {
    text-align: center;
    background-color: #fff;
    height: 425px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 101;
    width: 100%;
    border-top: #6D85F5 3px solid;
    overflow: hidden;
    display: block;
    .map-main {
        height: 100%;
        background-image: url(../../assets/img/map/bmap.png);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
    }
}
</style>
