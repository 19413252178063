<template>
  <div >
    <nav class="navbar navbar-expand-md fixed-top scrolling-navbar mainmenu-area" :class="{ menubg : state }">
			<div class="container">
				<a class="navbar-brand" >
          <img v-show="!state" src="../../assets/img/logo/nlogo.png">
          <img v-show="state" src="../../assets/img/logo/dlogo.png">
        </a>
				<button class="navbar-toggler" @click="toshowState" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
					<i class="lnr lnr-menu"></i>
				</button>
				<div class="collapse navbar-collapse" :class="{ show:showState }" id="navbarCollapse">
					<ul class="navbar-nav mr-auto w-100 justify-content-end">
						<li class="nav-item">
							<a class="nav-links" :class="{active:path_state == '/'}" @click="toPath('/')">首页</a>
						</li>
						<li class="nav-item">
							<a class="nav-links" :class="{active:path_state == '/news'}" @click="toPath('/news')">新闻动态</a>
						</li>
						<li class="nav-item">
							<a class="nav-links" :class="{active:path_state == '/about'}" @click="toPath('/about')">关于旺翔</a>
						</li>
						<!-- <li class="nav-item">
							<a class="nav-links dropdown-toggle" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">关于旺翔</a>
							<ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
					          <li class="sub-one"><a class="dropdown-item" href="about.html">About us</a></li>
					          <li class="sub-one"><a class="dropdown-item" href="javascript:void(0)">公司介绍</a></li>
					          <li class="sub-two"><a class="dropdown-item" href="javascript:void(0)">合作伙伴</a></li>
					          <li class="sub-three"><a class="dropdown-item" href="javascript:void(0)">发展历程</a></li>
					          <li class="sub-four"><a class="dropdown-item" href="javascript:void(0)">荣誉资质</a></li>
					          <li class="dropdown-submenu sub-five">
					          	<a class="dropdown-item dropdown-toggle" href="javascript:void(0)">投资者管理关系</a>
					            <ul class="dropdown-menu">
					              <li><a class="dropdown-item" href="javascript:void(0)">Submenu</a></li>
					              <li><a class="dropdown-item" href="javascript:void(0)">Submenu0</a></li>
					              <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:void(0)">Submenu 1</a>
					                <ul class="dropdown-menu">
					                  <li><a class="dropdown-item" href="javascript:void(0)">Subsubmenu1</a></li>
					                  <li><a class="dropdown-item" href="javascript:void(0)">Subsubmenu1</a></li>
					                </ul>
					              </li>
					            </ul>
					          </li>
					        </ul>
						</li> -->
						<li class="nav-item">
							<a class="nav-links" :class="{active:path_state == '/elite'}" @click="toPath('/elite')">招贤纳士</a>
						</li>
						<li class="nav-item login">
							<a @click="openDialog" data-lity="">注册</a>
						</li>
						<li class="nav-item register">
							<a @click="openDialog" data-lity="">登录</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
    <el-dialog width="940px" :visible="dialogVisible" :show-close="false" :before-close="handleClose">
      <InlinePops></InlinePops>
    </el-dialog>
  </div>
</template>

<script>
import InlinePops from "../inlinePops/inlinePops.vue";
export default {
  data() {
    return {
      dialogVisible:false,
      state:false,
      showState:false,
      path_state: '',
    };
  },
  components: { InlinePops },
  
  watch: {
    $route: {
      handler: function(val, oldVal){
        this.path_state = val.fullPath
      },
      // 深度观察监听
      deep: true,
      immediate:true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    openDialog(){
      this.dialogVisible = true
    },
    handleClose(done){
        this.dialogVisible = false
        done()
    },
    toshowState(){
      this.showState = this.showState ? false : true
    },
    handleScroll(e) {
      // 处理滚动事件的逻辑
      if(window.scrollY > 400){
        this.state = true
      }else{
        this.state = false
      }
    },
    toPath(path){
      if(!path){
        return false
      }
      if(this.path_state == path){
        return false
      }
      this.$router.push({path:path,query: {}})
      this.index_nav = 100
      this.showState = false
    },
    mouseoverNav(index){
      this.index_nav = index
    },
    mouseleaveNav(){
      this.index_nav = 100
    }
    
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }
};
</script>
<style scoped lang='less'>
.navbar-expand-md .navbar-toggler {
    background: #6D85F5;
    border-radius: 0px;
    margin: 5px;
    cursor: pointer;
    float: right;
    color: #fff;
}
.mainmenu-area {
    padding: 10px 0;
    border: none;
    margin: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    background: transparent;
    .nav-links {
      padding: 2px 20px 25px;
      color: #fff;
      font-size: 18px;
      position: relative;
      font-family: 'siyuanRegular';
  }
  .active {
      color: #6D85F5;
  }
  .login {
    margin: 0px 12px 0 12px;
    a {
        padding: 2px 6px;
        color: #6D85F5;
        border: 1px solid #6D85F5;
        border-radius: 4px;
        font-size: 18px;
    }
    a:hover{
        color: #6D85F5;
    }
  }
  .register a {
      padding: 2px 6px;
      color: #fff;
      background: linear-gradient(-90deg, #654FF1 0%, #73AFF7 100%);
      border: 1px solid #6D85F5;
      border-radius: 4px;
  }
  .register a:hover{
      color: #fff;
  }
}
.menubg {
    background-color: #fff !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    z-index: 999;
    padding: 6px 0 0 0;
    .nav-links {
        color: #333;
    }
    .active {
        color: #6D85F5 !important;
    }
}
</style>