<template>
    <div>
      <section id="elite-area" :style="'background-position:50% 50%;'">
        <div class="contents">
            <div class="container">
                <div class="contents-main">
                    <h2 class="wow fadeInDown media-txt animated" data-wow-duration="1000ms" data-wow-delay="0.3s" style="visibility: visible;-webkit-animation-duration: 1000ms; -moz-animation-duration: 1000ms; animation-duration: 1000ms;-webkit-animation-delay: 0.3s; -moz-animation-delay: 0.3s; animation-delay: 0.3s;">精英汇聚，我们需要你</h2>
                    <h2 class="wow fadeInUp bot-txt animated" data-wow-duration="1000ms" data-wow-delay="400ms" style="visibility: visible;-webkit-animation-duration: 1000ms; -moz-animation-duration: 1000ms; animation-duration: 1000ms;-webkit-animation-delay: 400ms; -moz-animation-delay: 400ms; animation-delay: 400ms;">Elite Convergence. We need you</h2>
                </div>
            </div>
        </div>
      </section>
      <section id="about-main elite-main" class="about-sec elite-sec">
        <div class="container">
            <div class="row">
                <div class="toggle-tabb">
                <div class="tabs">
                    <div class="tab" :class="{active: num == 1}" @click="toNum(1)">全部职位</div>
                    <div class="tab" :class="{active: num == 2}" @click="toNum(2)">技术职位</div>
                    <div class="tab" :class="{active: num == 3}" @click="toNum(3)">营业职位</div>
                </div>
                <div class="panels elite-tab">
                    <div class="panel" v-show="num == 1">
                        <div class="bs-example">
                            <div class="accordion" id="accordionExample">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h2 class="mb-0">
                                            <span>技术支持工程师</span>
                                            <button type="button" class="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseOne"  aria-expanded="false" @click="getChild(1)" >
                                                <i  class="fa" :class="{'fa-minus':!collapseOne,'fa-plus':collapseOne}"></i>
                                            </button>									
                                        </h2>
                                    </div>
                                    <div id="collapseOne" class="collapse show"  style="">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>及时响应用户的服务请求，向用户提供远程技术支持，保障软件产品的正常使用</li>
                                                    <li>负责用户使用产品过程中的问题反馈收集、回复与整理</li>
                                                    <li>良好的用户服务意识，学习力强，热情耐心，良好的团队合作精神，具有较好的敬业精神</li>
                                                    <li>配合相关技术人员进行数据整理和分析，对产品发展和运营提出建议。</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>计算机相关专业毕业，大专及以上学历</li>
                                                    <li>熟悉计算机软件及网络的相关技术</li>
                                                    <li>具有丰富的软件实施、服务经验</li>
                                                    <li>逻辑思维能力强，有一定的数据分析能力</li>
                                                    <li>具有较强的组织、计划、控制、协调能力和人际交往能力。</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h2 class="mb-0">
                                            <span>Linux运维工程师</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseTwo" @click="getChild(2)">
                                                <i  class="fa" :class="{'fa-minus':!collapseTwo,'fa-plus':collapseTwo}"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>负责服务器、网络设备等硬件设备以及虚拟化平台的安装，配置以及日常维护</li>
                                                    <li>进行办公设备的日常维护及管理；技术档案维护</li>
                                                    <li>负责系统及应用的安全，监控，优化，数据备份与恢复、故障解决等日常维护工作</li>
                                                    <li>处理网络及计算机故障</li>
                                                    <li>负责内部信息系统建设、维护；进行域名、后台数据、邮箱管理。</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>计算机或IT相关专业，大学本科</li>
                                                    <li>四年以上主流Linux（centos、ubuntu、redhat等）使用经验</li>
                                                    <li>熟悉Linux/Unix/Windows操作系统以及服务器群的网络搭建、设置和维护，网络的安全监控</li>
                                                    <li>熟悉互联网公司常用服务器(PC Server、小型机)、网络(四/七层交换、防火墙)、存储设备，以及拥有系统设计、优化经验；熟悉网络安全及其多种防火墙的配置；熟悉网站负载均衡技术，了解高访问量网站性能优化；善于分析和解决系统及网络故障。</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h2 class="mb-0">
                                            <span>Java高级开发工程师</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseThree" @click="getChild(3)">
                                                <i  class="fa" :class="{'fa-minus':!collapseThree,'fa-plus':collapseThree}"></i>
                                            </button>                     
                                        </h2>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>负责相关系统的核心模块的架构设计与开发</li>
                                                    <li>参与需求分析，完成功能模块的设计、开发、单元测试、集成和发布</li>
                                                    <li>参与软件产品底层技术架构研发，负责系统改造、技术优化、项目重构</li>
                                                    <li>解决开发过程中遇到的技术问题。</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>计算机相关专业毕业，大专及以上学历，热爱互联网产品研发</li>
                                                    <li>3年以上工作经验，有互联网应用开发经验，能够独立设计完整网站/系统</li>
                                                    <li>Java语言基础扎实，精通面向对象的分析和设计技术，理解常用设计模式</li>
                                                    <li>参与过两个以上中大型互联网项目</li>
                                                    <li>熟悉Memcached、Redis、Lucene、MongoDB等技术、有源码研究爱好者优先</li>
                                                    <li>诚信正直，主动积极，沟通能力强，有良好的团队精神。</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h2 class="mb-0">
                                            <span>iOS开发工程师</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseFour" @click="getChild(4)">
                                                <i  class="fa" :class="{'fa-minus':!collapseFour,'fa-plus':collapseFour}"></i>
                                            </button>                     
                                        </h2>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>主要负责iOS平台下iPhone/iPad的应用程序设计、开发，以及SDK的开发；并且熟悉Mac OS下Xcode软件开发</li>
                                                    <li>负责设计iOS客户端的产品构架，协助设计与系统的集成</li>
                                                    <li>根据项目任务计划按时完成软件开发和单元测试工作以及Bug修复</li>
                                                    <li>按照开发流程编写相应模块的设计文档</li>
                                                    <li>配合美术及策划人员能够优化现有产品的用户操作体验。</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>计算机相关专业毕业，大专及以上学历，1年以上iphone/ipad或Mac相关开发经验</li>
                                                    <li>精通Objective-C/Swift/Mac OS X/Xcode</li>
                                                    <li>精通iOS SDK中的UI、网络、数据库、XML/JSON解析等开发技巧</li>
                                                    <li>精通常用软件架构模式，熟悉各种算法与数据结构，多线程，网络编程</li>
                                                    <li>具备良好的编程习惯、文档写作、需求分析、程序设计的能力</li>
                                                    <li>有良好的分析问题和解决问题的能力。</li>
                                                    <li>有较强的学习能力及逻辑思维能力；</li>
                                                    <li>具备良好的沟通能力和团队合作精神；</li>
                                                    <li>有完整的iOS项目经验者优先。</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h2 class="mb-0">
                                            <span>广告销售专员</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseFive" @click="getChild(5)">
                                                <i  class="fa" :class="{'fa-minus':!collapseFive,'fa-plus':collapseFive}"></i>
                                            </button>                     
                                        </h2>
                                    </div>
                                    <div id="collapseFive" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>销售人员职位，在上级的领导和监督下定期完成量化的工作要求，并能独立处理和解决所负责的任务</li>
                                                    <li>管理客户关系，完成销售任务</li>
                                                    <li>了解和发掘客户需求及购买愿望，介绍自己产品的优点和特色</li>
                                                    <li>对客户提供专业的咨询</li>
                                                    <li>收集潜在客户资料</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>热爱广告传媒行业</li>
                                                    <li>有销售经验优先考虑。</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h2 class="mb-0">
                                            <span>互联网广告销售经理</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseSix" @click="getChild(6)">
                                                <i  class="fa" :class="{'fa-minus':!collapseSix,'fa-plus':collapseSix}"></i>
                                            </button>                     
                                        </h2>
                                    </div>
                                    <div id="collapseSix" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>负责基于大数据的程序化购买DSP、SSP、信息流广告产品的销售工作，并承担相应的销售指标</li>
                                                    <li>开拓客户需求，协调策划团队根据客户需求策划网络营销方案</li>
                                                    <li>负责与客户沟通提案及结案报告</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>大专及以上学历，广告、市场营销相关专业优先</li>
                                                    <li>具有三年以上互联网广告销售经验，熟悉互联网，尤其是DSP、SSP、信息流广告的售卖机制</li>
                                                    <li>具有大型国际知名品牌客户的互联网广告销售与客户服务经验优先</li>
                                                    <li>具备较强的学习能力和适应能力，能快速融入新环境并在高强压力下工作</li>
                                                    <li>能适应出差。</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h2 class="mb-0">
                                            <span>商务经理</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseSeven" @click="getChild(7)">
                                                <i  class="fa" :class="{'fa-minus':!collapseSeven,'fa-plus':collapseSeven}"></i>
                                            </button>                     
                                        </h2>
                                    </div>
                                    <div id="collapseSeven" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>大专及以上学历，广告、市场营销相关专业优先</li>
                                                    <li>具有三年以上互联网广告销售经验，熟悉互联网，尤其是DSP、SSP、信息流广告的售卖机制</li>
                                                    <li>参与CP合作项目的进程，对合作结果进行评估和处理，拓展并维护公司品牌</li>
                                                    <li>整理、收集、分析行业动态，对竟争对手的合作情况进行监控和分析</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>有一定的手机游戏cp资源者优先</li>
                                                    <li>了解手机游戏产品和市场，对产品和市场均有良好的判断能力</li>
                                                    <li>工作细致。</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h2 class="mb-0">
                                            <span>公关文案</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseEight" @click="getChild(8)">
                                                <i  class="fa" :class="{'fa-minus':!collapseEight,'fa-plus':collapseEight}"></i>
                                            </button>                     
                                        </h2>
                                    </div>
                                    <div id="collapseEight" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>建立、保持与媒体的良好关系，组织实施新闻发布活动，协调与企业附属公司宣传部门的关系</li>
                                                    <li>协助主管制定日常宣传的传播计划，撰写和发布官方新闻公告和媒体文章，监督媒体宣传效果</li>
                                                    <li>负责市场活动的策划、组织工作</li>
                                                    <li>制定各类公关活动方案，并组织协调各部门资源来推动方案的实施，确保方案在预算内及时完成,并达到相应的宣传目标</li>
                                                    <li>根据传播进行执行反馈，分析宣传效果，为以后的传播工作提供参考</li>
                                                    <li>文档的收集整理，建立公司宣传档案</li>
                                                    <li>组织媒体活动。</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>本科以上学历，公关、新闻传播学、市场营销等相关专业优先</li>
                                                    <li>熟悉互联网行业，两年以上公关媒介及策划工作经验，有一定的媒体关系资源</li>
                                                    <li>对公关理念有深刻的了解， 在执行层面之上有自己的见解</li>
                                                    <li>具备较强的市场意识，思维活跃，能根据业务需求策划和组织相关活动</li>
                                                    <li>文字功底好，熟悉新闻等文章模式的写作，有较强的文字处理能力</li>
                                                    <li>有较强的执行能力，能协调资源来完成计划并作出评估。</li>
                                                    <li>性格开朗，务实，沟通能力较强，同时能承受较大的工作压力。</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h2 class="mb-0">
                                            <span>运营优化师</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseNine" @click="getChild(9)">
                                                <i  class="fa" :class="{'fa-minus':!collapseNine,'fa-plus':collapseNine}"></i>
                                            </button>                     
                                        </h2>
                                    </div>
                                    <div id="collapseNine" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>负责广告投放计划制定以及日常投放，提取广告数据报表，分析投放效果并优化投放策略</li>
                                                    <li>负责广告素材的协调，协助设计制定广告创意，并跟踪优化</li>
                                                    <li>和客户进行良好的沟通</li>
                                                    <li>有效完成上级所制定各项任务目标</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>做事踏实负责有耐心</li>
                                                    <li>熟悉计算机软件及网络的相关技术</li>
                                                    <li>有主流广告交易平台或者腾讯广点通、FaceBook、今日头条、SEM等投放经验或者广告代理公司运营经验者优先</li>
                                                    <li>具备较强的沟通协调能力，善于学习新东西</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel" v-show="num == 2">
                        <div class="bs-example">
                            <div class="accordion" id="accordionExample2">
                                <div class="card">
                                    <div class="card-header" id="headingOne2">
                                        <h2 class="mb-0">
                                            <span>技术支持工程师</span>
                                            <button type="button" class="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseOne2" @click="getChild(10)">
                                                <i  class="fa" :class="{'fa-minus':!collapseOne2,'fa-plus':collapseOne2}"></i>
                                            </button>									
                                        </h2>
                                    </div>
                                    <div id="collapseOne2" class="collapse show" aria-labelledby="headingOne2" data-parent="#accordionExample2">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>及时响应用户的服务请求，向用户提供远程技术支持，保障软件产品的正常使用</li>
                                                    <li>负责用户使用产品过程中的问题反馈收集、回复与整理</li>
                                                    <li>良好的用户服务意识，学习力强，热情耐心，良好的团队合作精神，具有较好的敬业精神</li>
                                                    <li>配合相关技术人员进行数据整理和分析，对产品发展和运营提出建议。</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>计算机相关专业毕业，大专及以上学历</li>
                                                    <li>熟悉计算机软件及网络的相关技术</li>
                                                    <li>具有丰富的软件实施、服务经验</li>
                                                    <li>逻辑思维能力强，有一定的数据分析能力</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo3">
                                        <h2 class="mb-0">
                                            <span>Linux运维工程师</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseTwo3" @click="getChild(11)">
                                                <i  class="fa" :class="{'fa-minus':!collapseTwo3,'fa-plus':collapseTwo3}"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo3" class="collapse" aria-labelledby="headingTwo3" data-parent="#accordionExample2">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>负责服务器、网络设备等硬件设备以及虚拟化平台的安装，配置以及日常维护</li>
                                                    <li>进行办公设备的日常维护及管理；技术档案维护</li>
                                                    <li>负责系统及应用的安全，监控，优化，数据备份与恢复、故障解决等日常维护工作</li>
                                                    <li>处理网络及计算机故障</li>
                                                    <li>负责内部信息系统建设、维护；进行域名、后台数据、邮箱管理。</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>计算机或IT相关专业，大学本科</li>
                                                    <li>四年以上主流Linux（centos、ubuntu、redhat等）使用经验</li>
                                                    <li>熟悉Linux/Unix/Windows操作系统以及服务器群的网络搭建、设置和维护，网络的安全监控</li>
                                                    <li>熟悉互联网公司常用服务器(PC Server、小型机)、网络(四/七层交换、防火墙)、存储设备，以及拥有系统设计、优化经验；熟悉网络安全及其多种防火墙的配置；熟悉网站负载均衡技术，了解高访问量网站性能优化；善于分析和解决系统及网络故障。</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree4">
                                        <h2 class="mb-0">
                                            <span>Java高级开发工程师</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseThree4" @click="getChild(12)">
                                                <i  class="fa" :class="{'fa-minus':!collapseThree4,'fa-plus':collapseThree4}"></i>
                                            </button>                     
                                        </h2>
                                    </div>
                                    <div id="collapseThree4" class="collapse" aria-labelledby="headingThree4" data-parent="#accordionExample2">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>负责相关系统的核心模块的架构设计与开发</li>
                                                    <li>参与需求分析，完成功能模块的设计、开发、单元测试、集成和发布</li>
                                                    <li>参与软件产品底层技术架构研发，负责系统改造、技术优化、项目重构</li>
                                                    <li>解决开发过程中遇到的技术问题。</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>计算机相关专业毕业，大专及以上学历，热爱互联网产品研发</li>
                                                    <li>3年以上工作经验，有互联网应用开发经验，能够独立设计完整网站/系统</li>
                                                    <li>Java语言基础扎实，精通面向对象的分析和设计技术，理解常用设计模式</li>
                                                    <li>参与过两个以上中大型互联网项目</li>
                                                    <li>熟悉Memcached、Redis、Lucene、MongoDB等技术、有源码研究爱好者优先</li>
                                                    <li>诚信正直，主动积极，沟通能力强，有良好的团队精神。</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree5">
                                        <h2 class="mb-0">
                                            <span>iOS开发工程师</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseFour5" @click="getChild(13)">
                                                <i  class="fa" :class="{'fa-minus':!collapseFour5,'fa-plus':collapseFour5}"></i>
                                            </button>                     
                                        </h2>
                                    </div>
                                    <div id="collapseFour5" class="collapse" aria-labelledby="headingThree5" data-parent="#accordionExample2">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>主要负责iOS平台下iPhone/iPad的应用程序设计、开发，以及SDK的开发；并且熟悉Mac OS下Xcode软件开发</li>
                                                    <li>负责设计iOS客户端的产品构架，协助设计与系统的集成</li>
                                                    <li>根据项目任务计划按时完成软件开发和单元测试工作以及Bug修复</li>
                                                    <li>按照开发流程编写相应模块的设计文档</li>
                                                    <li>配合美术及策划人员能够优化现有产品的用户操作体验。</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>计算机相关专业毕业，大专及以上学历，1年以上iphone/ipad或Mac相关开发经验</li>
                                                    <li>精通Objective-C/Swift/Mac OS X/Xcode</li>
                                                    <li>精通iOS SDK中的UI、网络、数据库、XML/JSON解析等开发技巧</li>
                                                    <li>精通常用软件架构模式，熟悉各种算法与数据结构，多线程，网络编程</li>
                                                    <li>具备良好的编程习惯、文档写作、需求分析、程序设计的能力</li>
                                                    <li>有良好的分析问题和解决问题的能力。</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel" v-show="num == 3">
                        <div class="bs-example">
                            <div class="accordion" id="accordionExample3">
                                <div class="card">
                                    <div class="card-header" id="headingThree6">
                                        <h2 class="mb-0">
                                            <span>广告销售专员</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseFive6" @click="getChild(14)">
                                                <i  class="fa" :class="{'fa-minus':!collapseFive6,'fa-plus':collapseFive6}"></i>
                                            </button>                     
                                        </h2>
                                    </div>
                                    <div id="collapseFive6" class="collapse show" aria-labelledby="headingThree6" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>销售人员职位，在上级的领导和监督下定期完成量化的工作要求，并能独立处理和解决所负责的任务</li>
                                                    <li>管理客户关系，完成销售任务</li>
                                                    <li>了解和发掘客户需求及购买愿望，介绍自己产品的优点和特色</li>
                                                    <li>对客户提供专业的咨询</li>
                                                    <li>收集潜在客户资料</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>热爱广告传媒行业</li>
                                                    <li>有销售经验优先考虑。</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree7">
                                        <h2 class="mb-0">
                                            <span>互联网广告销售经理</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseSix7" @click="getChild(15)">
                                                <i  class="fa" :class="{'fa-minus':!collapseSix7,'fa-plus':collapseSix7}"></i>
                                            </button>                     
                                        </h2>
                                    </div>
                                    <div id="collapseSix7" class="collapse" aria-labelledby="headingThree7" data-parent="#accordionExample6">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>负责基于大数据的程序化购买DSP、SSP、信息流广告产品的销售工作，并承担相应的销售指标</li>
                                                    <li>开拓客户需求，协调策划团队根据客户需求策划网络营销方案</li>
                                                    <li>负责与客户沟通提案及结案报告</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>大专及以上学历，广告、市场营销相关专业优先</li>
                                                    <li>具有三年以上互联网广告销售经验，熟悉互联网，尤其是DSP、SSP、信息流广告的售卖机制</li>
                                                    <li>具有大型国际知名品牌客户的互联网广告销售与客户服务经验优先</li>
                                                    <li>具备较强的学习能力和适应能力，能快速融入新环境并在高强压力下工作</li>
                                                    <li>能适应出差。</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree8">
                                        <h2 class="mb-0">
                                            <span>商务经理</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseSeven8" @click="getChild(16)">
                                                <i  class="fa" :class="{'fa-minus':!collapseSeven8,'fa-plus':collapseSeven8}"></i>
                                            </button>                     
                                        </h2>
                                    </div>
                                    <div id="collapseSeven8" class="collapse" aria-labelledby="headingThree8" data-parent="#accordionExample6">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>大专及以上学历，广告、市场营销相关专业优先</li>
                                                    <li>具有三年以上互联网广告销售经验，熟悉互联网，尤其是DSP、SSP、信息流广告的售卖机制</li>
                                                    <li>参与CP合作项目的进程，对合作结果进行评估和处理，拓展并维护公司品牌</li>
                                                    <li>整理、收集、分析行业动态，对竟争对手的合作情况进行监控和分析</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>有一定的手机游戏cp资源者优先</li>
                                                    <li>了解手机游戏产品和市场，对产品和市场均有良好的判断能力</li>
                                                    <li>工作细致。</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree9">
                                        <h2 class="mb-0">
                                            <span>公关文案</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseEight9" @click="getChild(17)">
                                                <i  class="fa" :class="{'fa-minus':!collapseEight9,'fa-plus':collapseEight9}"></i>
                                            </button>                     
                                        </h2>
                                    </div>
                                    <div id="collapseEight9" class="collapse" aria-labelledby="headingThree9" data-parent="#accordionExample6">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>建立、保持与媒体的良好关系，组织实施新闻发布活动，协调与企业附属公司宣传部门的关系</li>
                                                    <li>协助主管制定日常宣传的传播计划，撰写和发布官方新闻公告和媒体文章，监督媒体宣传效果</li>
                                                    <li>负责市场活动的策划、组织工作</li>
                                                    <li>制定各类公关活动方案，并组织协调各部门资源来推动方案的实施，确保方案在预算内及时完成,并达到相应的宣传目标</li>
                                                    <li>根据传播进行执行反馈，分析宣传效果，为以后的传播工作提供参考</li>
                                                    <li>文档的收集整理，建立公司宣传档案</li>
                                                    <li>组织媒体活动。</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>本科以上学历，公关、新闻传播学、市场营销等相关专业优先</li>
                                                    <li>熟悉互联网行业，两年以上公关媒介及策划工作经验，有一定的媒体关系资源</li>
                                                    <li>对公关理念有深刻的了解， 在执行层面之上有自己的见解</li>
                                                    <li>具备较强的市场意识，思维活跃，能根据业务需求策划和组织相关活动</li>
                                                    <li>文字功底好，熟悉新闻等文章模式的写作，有较强的文字处理能力</li>
                                                    <li>有较强的执行能力，能协调资源来完成计划并作出评估。</li>
                                                    <li>性格开朗，务实，沟通能力较强，同时能承受较大的工作压力。</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree10">
                                        <h2 class="mb-0">
                                            <span>运营优化师</span>
                                            <button type="button" class="btn btn-link " data-bs-toggle="collapse" data-bs-target="#collapseNine10" @click="getChild(18)">
                                                <i  class="fa" :class="{'fa-minus':!collapseNine10,'fa-plus':collapseNine10}"></i>
                                            </button>                     
                                        </h2>
                                    </div>
                                    <div id="collapseNine10" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample6">
                                        <div class="card-body">
                                            <div class="c-b-inner"><h3>工作地点：上海</h3></div>
                                            <div class="c-b-inner">
                                                <h3>职位描述：</h3>
                                                <ol>
                                                    <li>负责广告投放计划制定以及日常投放，提取广告数据报表，分析投放效果并优化投放策略</li>
                                                    <li>负责广告素材的协调，协助设计制定广告创意，并跟踪优化</li>
                                                    <li>和客户进行良好的沟通</li>
                                                    <li>有效完成上级所制定各项任务目标</li>
                                                </ol>
                                            </div>
                                            <div class="c-b-inner">
                                                <h3>岗位要求：</h3>
                                                <ol>
                                                    <li>做事踏实负责有耐心</li>
                                                    <li>熟悉计算机软件及网络的相关技术</li>
                                                    <li>有主流广告交易平台或者腾讯广点通、FaceBook、今日头条、SEM等投放经验或者广告代理公司运营经验者优先</li>
                                                    <li>具备较强的沟通协调能力，善于学习新东西</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        num : 1,
        collapseOne:false,
        collapseTwo:true,
        collapseThree:true,
        collapseFour:true,
        collapseFive:true,
        collapseSix:true,
        collapseSeven:true,
        collapseEight:true,
        collapseNine:true,
        collapseOne2:false,
        collapseTwo3:true,
        collapseThree4:true,
        collapseFour5:true,
        collapseFive6:false,
        collapseSix7:true,
        collapseSeven8:true,
        collapseEight9:true,
        collapseNine10:true
      };
    },
    components: {  },
    mounted() {
    },
    methods: {
        getChild(e){
            switch(e){
                case 1:
                this.collapseOne = this.collapseOne ? false :true;
                break;
                case 2:
                this.collapseTwo = this.collapseTwo ?  false : true;
                break;
                case 3:
                this.collapseThree = this.collapseThree ? false :true;
                break;
                case 4:
                this.collapseFour = this.collapseFour ? false :true;
                break;
                case 5:
                this.collapseFive = this.collapseFive ? false :true;
                break;
                case 6:
                this.collapseSix = this.collapseSix ? false :true;
                break;
                case 7:
                this.collapseSeven = this.collapseSeven ? false :true;
                break;
                case 8:
                this.collapseEight = this.collapseEight ? false :true;
                break;
                case 9:
                this.collapseNine = this.collapseNine ? false :true;
                break;
                case 10:
                this.collapseOne2 = this.collapseOne2 ? false :true;
                break;
                case 11:
                this.collapseTwo3 = this.collapseTwo3 ? false :true;
                break;
                case 12:
                this.collapseThree4 = this.collapseThree4 ? false :true;
                break;
                case 13:
                this.collapseFour5 = this.collapseFour5 ? false :true;
                break;
                case 14:
                this.collapseFive6 = this.collapseFive6 ? false :true;
                break;
                case 15:
                this.collapseSix7 = this.collapseSix7 ? false :true;
                break;
                case 16:
                this.collapseSeven8 = this.collapseSeven8 ? false :true;
                break;
                case 17:
                this.collapseEight9 = this.collapseEight9 ? false :true;
                break;
                case 18:
                this.collapseNine10 = this.collapseNine10 ? false :true;
                break;
            }
        },

        currentChange(i){
            this.num = i
        },
      toNum(num){
        this.num = num
      },
      toPath(path,target){
        if(!path){
          return
        }
        if(path.includes(this.OUT_URL)){
          window.open(path, '_blank');
        }else{
          this.$router.push({path:path,query: {}})
        }
      }
    },
  };
  </script>
  <style lang='less' src="./index.less"  >
  </style>