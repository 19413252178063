import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Home/Home'
import news from '../views/news/news'
import elite from '../views/elite/elite'
import about from '../views/about/about'
import cpd from '../views/platform/cpd'
import ssp from '../views/platform/ssp'
// import contact from '../views/newEdition/contact/index.vue'
// import honor from '../views/newEdition/honor/index.vue'
// import history from '../views/newEdition/history/index.vue'
// import doctor from '../views/newEdition/product/doctor.vue'
// import drugstore from '../views/newEdition/product/drugstore.vue'
// import patient from '../views/newEdition/product/patient.vue'
// import news from '../views/newEdition/news/index.vue'
// import newsDetail from '../views/newEdition/news/detail.vue'
// import text from '../views/newEdition/product/text.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: {title: '国内领先的数字营销服务平台'},
  },
  {
    path: '/news',
    name: 'news',
    component: news,
    meta: {title: '国内领先的数字营销服务平台'},
  },
  {
    path: '/elite',
    name: 'elite',
    component: elite,
    meta: {title: '国内领先的数字营销服务平台'},
  },
  {
    path: '/about',
    name: 'about',
    component: about,
    meta: {title: '国内领先的数字营销服务平台'},
  },
  {
    path: '/cpd',
    name: 'cpd',
    component: cpd,
    meta: {title: '国内领先的数字营销服务平台'},
  },
  {
    path: '/ssp',
    name: 'ssp',
    component: ssp,
    meta: {title: '国内领先的数字营销服务平台'},
  },

  
  
  // {
  //   path: '/introduction',
  //   name: 'introduction',
  //   component: introduction
  // },
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   component: contact
  // },
  // {
  //   path: '/honor',
  //   name: 'honor',
  //   component: honor
  // },
  // {
  //   path: '/history',
  //   name: 'history',
  //   component: history
  // },
  // {
  //   path: '/doctor',
  //   name: 'doctor',
  //   component: doctor
  // },
  // {
  //   path: '/drugstore',
  //   name: 'drugstore',
  //   component: drugstore
  // },
  // {
  //   path: '/patient',
  //   name: 'patient',
  //   component: patient
  // },
  // {
  //   path: '/news',
  //   name: 'news',
  //   component: news
  // },
  // {
  //   path: '/newsDetail',
  //   name: 'newsDetail',
  //   component: newsDetail
  // },
  // {
  //   path: '/text',
  //   name: 'text',
  //   component: text
  // },

  
]

const router = new VueRouter({
    // 正式
  mode: 'history',
//   base: 'pc',
    // //测试
    // mode: 'history',
    // base: 'adunite',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }


})

export default router
