<template>
    <div>
        <div id="inline-pops" style="background: rgb(255, 255, 255); max-height: 969px;" class="">
			<div class="channel-banner">
				<img src="../../assets/img/popup/channelbanner.png" alt="channelbanner">
			</div>
			<div class="hmibg hmibg-top">
				<div class="pop-logo-main">
					<img src="../../assets/img/popup/hmobi-logo.png" alt="hotmobi-logo">
				</div>
				<div class="hmibg-info" style="margin-left: 152px;">
					<div class="hbg-info-left">
						<a href="https://www.hotmobi.com" target="_blank">
							<img src="../../assets/img/popup/icon-channel.png" alt="channel icon">
							<!-- <img src="../../assets/img/popup/channelFont.png" alt="channel text"> -->
              <div class="ttt">
                Media
                <i class="el-icon-d-arrow-right"></i>
              </div>
						</a>
					</div>
					<div class="hbg-info-right">
						<a href="https://www.hotmobi.com" target="_blank">
							<img src="../../assets/img/popup/icon-ad.png" alt="user icon">
							<!-- <img src="../../assets/img/popup/advertFont.png" alt="advertise text"> -->
              <div class="ttt">
                Advertiser
                <i class="el-icon-d-arrow-right"></i>
              </div>
						</a>
					</div>
				</div>
			</div>
			<div class="hmibg hmibg-bot">
				<div class="pop-logo-main">
					<img src="../../assets/img/popup/adu-logo-1.png" alt="hotmobi-logo">
				</div>
				<div class="hmibg-info">
					<div class="hbg-info-left" style="padding-right: 85px;">
						<a href="https://adx.adunite.com/" target="_blank">
							<img src="../../assets/img/popup/icon-channel.png" alt="channel icon">
							<!-- <img src="../../assets/img/popup/channelFont.png" alt="channel text"> -->
              <div class="ttt">
                媒体
                <i class="el-icon-d-arrow-right"></i>
              </div>
              
						</a>
					</div>
					<div class="hbg-info-right">
            <a href="https://dsp.adunite.com/" target="_blank">
							<img src="../../assets/img/popup/icon-ad.png" alt="user icon">
						  <!-- <img src="../../assets/img/popup/advertFont.png" alt="advertise text"> -->
              <div class="ttt">
                广告主
                <i class="el-icon-d-arrow-right"></i>
              </div>
						</a>
					</div>
				</div>
			</div>
		</div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
          state:false,
          path_state:''
      };
    },
    components: {},
    
    watch: {
      $route: {
        handler: function(val, oldVal){
          this.path_state = val.fullPath
        },
        // 深度观察监听
        deep: true,
        immediate:true
      }
    },
    mounted() {
  
    },
    methods: {
      map(){
        this.state = this.state ? false : true
      },
      toPath(path){
        if(!path){
          return
        }
        if(this.path_state == path){
          return false
        }
        if(path.includes(this.OUT_URL)){
          window.open(path, '_blank');
        }else{
          this.$router.push({path:path,query: {}})
        }
      }
    },
  };
  </script>
  
  <style lang='less' >
#inline-pops {
    width: auto;
    height: auto;
    // padding: 30px;
    box-sizing: border-box;
    .channel-banner {
        margin-bottom: 40px;
    }
    .hmibg {
        width: 100%;
        height: 178px;
        margin-bottom: 20px;
        padding-left: 50px;
        background-image: url(../../assets/img/popup/hotmobibg.png);
        background-repeat: no-repeat;
        background-position: -10px -10px;
        background-size: cover;
        .pop-logo-main {
            width: 272px;
            float: left;
        }
        .hmibg-info {
            margin-left: 155px;
            float: left;
            .hbg-info-left {
                padding-right: 65px;
            }
        }
        .hmibg-top img, .hmibg-bot img {
            padding-top: 68.5px;
            padding-right: 15px;
        }
    }
    .hmibg img, .hbg-info-left, .hbg-info-right, .hmibg-info {
        float: left;
    }
    .hmibg-top img, .hmibg-bot img {
        padding-top: 68.5px;
        padding-right: 15px;
    }
    .ttt{
      display: inline-block;
      padding-top: 62.5px;
      padding-right: 15px;
      color: #444444;
      font-size: 20px;
    }
}
@media (max-width: 640px){
    .el-dialog{
        width:100% !important;
    }
    .channel-banner{
        display:none;
    }
    .hmibg-info{
        margin-left:0 !important;
    }
    .hbg-info-left {
        padding-right: 0 !important;
    }
    .hmibg-top img, .hmibg-bot img {
        padding-top: 36.5px !important;
        padding-right: 10px !important;
    }
    .ttt{
      padding-top: 30.5px !important;
      padding-right: 20px !important;
    }
}
  </style>
  