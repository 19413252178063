<template>
  <div id="app">
    <Nav></Nav>
    <router-link to="/"></router-link>
    <!-- <router-view v-if="isRouterAlive" /> -->


    <router-view />
    <Bottom></Bottom>
  </div>
</template>
<script>
import Nav from "./components/nav/nav.vue";
import Bottom from "./components/bottom/bottom.vue";
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  components: { Nav,Bottom },
  mounted() {
    
  },
  methods: {

  },
};
</script>
<style  src="../src/assets/css/responsive.css"  >
</style>
<style  src="../src/assets/css/flexslider.css"  >
</style>
<style  src="../src/assets/css/line-icons.css"  >
</style>
<style  src="../src/assets/css/animate.css"  >
</style>
<style  src="../src/assets/css/font-awesome.min.css"  >
</style>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  cursor: pointer;
  color: #505E77;
  text-decoration: none !important;
}
ul {

list-style-type: none;

}
.lineOne{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}



</style>
